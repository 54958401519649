.background {
  background-color: #f4d421;
}
.background-img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-image: url('./assets/background.png');
  background-size: contain;
  background-repeat: repeat;
}
.main-page {
  border: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.bgm-btn {
  z-index: 100;
  position: absolute;
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  background-image: url(./assets/bgm-btn.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.bgm-anim {
  animation: bgm-rorate 1.5s linear 0s infinite forwards;
}
@keyframes bgm-rorate {
  0% {
    transform: rotateZ(0deg);
  }
  50% {
    transform: rotateZ(180deg);
  }
  100% {
    transform: rotateZ(360deg);
  }
}
.pad-question {
  margin-top: 50px;
  width: 100%;
  text-align: center;
}
.pad-question .pad-bg {
  display: inline-block;
  position: relative;
}
.pad-question .pad-bg .heart-bg {
  display: inline-block;
  width: 258px;
  animation: heart-scale 0.5s cubic-bezier(0.99, 1.9, 0.56, 0.73) 0s 1 forwards;
}
.pad-question .pad-bg .lightning {
  position: absolute;
  top: -14px;
  left: 37.5px;
  width: 198.5px;
}
.pad-question .pad-bg .horn {
  position: absolute;
  left: -25px;
  top: 90px;
  width: 47px;
  height: 46px;
  transform: rotateZ(333deg);
  animation: horn-shake 1s ease-in-out 0s infinite;
}
.pad-question .pad-bg .alarm-clock {
  position: absolute;
  top: 0;
  right: -25px;
  width: 47px;
  height: 47px;
}
.pad-question .pad-bg .girl {
  position: absolute;
  left: 0;
  bottom: -25px;
  width: 99px;
  height: 95px;
}
.pad-question .pad-bg .small-heart1 {
  position: absolute;
  left: 60px;
  top: -10px;
  width: 15px;
  height: 13px;
  animation: heart-scale 2s ease 0s infinite;
}
.pad-question .pad-bg .small-heart2 {
  position: absolute;
  left: 105px;
  top: 0px;
  width: 20px;
  height: 17px;
  animation: heart-scale 2s ease 0.2s infinite;
}
.pad-question .pad-bg .small-heart3 {
  position: absolute;
  left: 80px;
  top: 20px;
  width: 19px;
  height: 17px;
  animation: heart-scale 2s ease 0.3s infinite;
}
.pad-question .pad-bg .text {
  position: absolute;
  top: 45px;
  left: 40px;
  width: 180px;
  color: white;
  font-size: 20px;
  transform: scale(0);
  line-height: 24px;
  animation: question-fade 1s cubic-bezier(0.99, 1.9, 0.56, 0.73) 0.2s 1 forwards;
}
@keyframes heart-scale {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes horn-shake {
  0% {
    transform: scale(1) rotateZ(333deg);
  }
  10% {
    transform: scale(1.2) rotateZ(333deg);
  }
  20% {
    transform: scale(1.2) rotateZ(328deg);
  }
  30% {
    transform: scale(1.2) rotateZ(333deg);
  }
  40% {
    transform: scale(1.2) rotateZ(338deg);
  }
  50% {
    transform: scale(1.2) rotateZ(333deg);
  }
  60% {
    transform: scale(1.2) rotateZ(328deg);
  }
  70% {
    transform: scale(1.2) rotateZ(333deg);
  }
  80% {
    transform: scale(1.2) rotateZ(338deg);
  }
  90% {
    transform: scale(1.2) rotateZ(333deg);
  }
  100% {
    transform: scale(1) rotateZ(333deg);
  }
}
@keyframes question-fade {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
.pad-answer {
  display: flex;
  height: calc(100% - 260px);
  align-items: center;
  animation: move-transform 0.8s cubic-bezier(0.99, 1.9, 0.56, 0.73) 0.3s forwards;
  transform: translateX(475px);
}
.pad-answer.row {
  flex-direction: row;
  justify-content: space-around;
}
.pad-answer.column {
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.question-index {
  position: absolute;
  text-align: center;
  bottom: 10px;
  left: 0;
  width: 100%;
}
.logo {
  position: absolute;
  width: 80px;
  height: 17px;
  top: 15px;
  left: 15px;
  z-index: 10;
}
.answer-option {
  position: relative;
  text-align: center;
}
.answer-option .option-img {
  display: flex;
  flex-direction: column;
}
.answer-option .option-img > img {
  height: 90px;
}
.answer-option .option-img > span {
  margin-top: 10px;
}
.answer-option .option-text {
  display: block;
  padding: 10px 10px;
  white-space: wrap;
  text-align: center;
  font-size: 20px;
  margin: 10px 15px 0;
  border-radius: 10px;
  border: 2px solid #1a1a1a;
  color: #28292b;
}
@keyframes move-transform {
  0% {
    transform: translateX(375px);
  }
  100% {
    transform: translateX(0);
  }
}
.pad-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.pad-cover .cover-content {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
}
.pad-cover .cool-guy {
  position: absolute;
  bottom: 0px;
  right: 0;
  width: 347px;
  pointer-events: none;
}
.pad-cover .examination-title {
  text-align: center;
  font-size: 40px;
  margin-top: 80px;
}
.pad-cover .sub-title {
  text-align: center;
  font-size: 20px;
  margin-top: 20px;
}
.pad-cover .description {
  text-align: center;
  margin-top: 50px;
  line-height: 30px;
  color: #fa0d2f;
  font-size: 18px;
}
.pad-cover .button-wrap {
  margin-top: 25px;
  text-align: center;
  z-index: 1;
}
.pad-description {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pad-description .section {
  margin-bottom: 25px;
  line-height: 28px;
}
.pad-description .title {
  margin-bottom: 25px;
  font-size: 30px;
}
.pad-description .button-wrap {
  margin-top: 50px;
}
.pad-description .pad-input-name .desc {
  font-size: 18px;
  display: block;
  margin-bottom: 15px;
}
.pad-description .pad-input-name .input {
  font-size: 18px;
  display: inline-block;
  text-align: center;
}
.pad-description .pad-input-name .error {
  font-size: 13px;
  display: block;
  color: #ff5448;
}
.button {
  border: 0;
  outline: 0;
  background-color: #ff5448;
  border-radius: 9px;
  box-shadow: #ff5448;
  color: white;
  width: 200px;
  height: 48px;
  font-size: 20px;
}
.pad-result {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  color: #28292b;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.pad-result .pad-score {
  text-align: center;
  font-size: 30px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}
.pad-result .pad-desc {
  text-align: center;
  margin-top: 20px;
}
.pad-result .result-text {
  font-size: 25px;
  padding: 0 15%;
}
.pad-result .resut-image {
  width: 220px;
  height: 220px;
  margin-top: 0px;
}
.pad-result .buttons {
  text-align: center;
  margin-top: 15px;
}
.pad-result .btn {
  background-color: #f2a653;
  border: 0;
  outline: 0;
  color: white;
  border-radius: 6px;
  width: 115px;
  height: 48px;
  margin: 0 20px;
  font-size: 16px;
}
.pad-result .pad-download {
  text-align: center;
  margin-top: 15px;
}
.pad-result .pad-download .btn {
  width: 150px;
}
.pad-result .pad-download .txt {
  margin-top: 10px;
}
.pad-share-mask {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.pad-share {
  background-color: rgba(255, 255, 255, 0.6);
  position: absolute;
  height: 200px;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: white;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.pad-share .close {
  position: absolute;
  right: 0;
  top: 0;
}
.pad-share .close > img {
  width: 44px;
  height: 44px;
}
.pad-share .share-text {
  text-align: center;
  margin-top: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}
.pad-share .share-buttons {
  margin-top: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.pad-share .share-buttons .share-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.pad-share .share-buttons img {
  width: 40px;
  height: 40px;
}
.pad-share .share-buttons .text {
  text-align: center;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  margin-top: 10px;
}
